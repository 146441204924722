
&__nossos_numeros {
    background: #F5F5F5;
    >.container {
        position: relative;
        padding-top: 80px;
    }
    &__separator {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        >div {
            &:first-child {
                background-image: repeating-linear-gradient(90deg, $secondaryColor 0%, $secondaryColor 5px, transparent 5px, transparent 10px);
                flex: 1;
                height: 2px;
                position: relative;
                >div {
                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(90deg, #F5F5F5 0%, transparent 100%);
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
            &:nth-of-type(2) {
                width: 32px;
                height: 32px;
            }
            &:last-child {
                background-image: repeating-linear-gradient(90deg, $primaryColor 0%, $primaryColor 5px, rgba(0, 163, 223, 0) 5px, rgba(0, 163, 223, 0) 10px);
                flex: 1;
                height: 2px;
                >div {
                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(90deg, transparent 0%, #F5F5F5 100%);
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }
    &__introduction {
        margin: 0;
        h1,h2,h3,h4,h5 {
            text-align: center;
            color: $textColor;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-size: px(36);
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            margin: 0 0 24px;
            mark,b,i,strong {
                background: linear-gradient(90deg, $secondaryColor 0%, $tertiaryColor 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                leading-trim: both;
                text-edge: cap;
                font-size: px(36);
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
            }
        }
        p {
            color: #30313B;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-size: px(18);
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            max-width: 800px;
            margin: 0 auto; 
            b, strong {
                font-weight: 700;
            }
        }
    }
    &__items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;
        padding: 40px 0 72px;
        @include mobile {
            gap: 32px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &__item {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &__number {
                color: $textColor;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-size: px(56);
                font-style: normal;
                font-weight: 700;
                line-height: 64px; 
                letter-spacing: -1.12px;

                display: flex;
                gap: 13px;
            
                >span.js-counter {
                    margin: 0 -11px;
                }
            }
            &__description {
                margin: -3px 0 0;
                text-align: center;
                color: #30313B;
                leading-trim: both;
                text-edge: cap;
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                strong, b {
                    font-weight: 700;
                }
            }
        }
    }
}
