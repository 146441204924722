
&__central_de_resultados {
    background: #1B1B21;
    padding: 72px 0;
    &__box {
        border-radius: 16px;
        min-height: 411px; 
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border-radius: 16px;
            background: linear-gradient(to right, $secondaryColor, $tertiaryColor);
            z-index: -2;
            transition: all .3s;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            background: #1B1B21;
            z-index: -1;
            transition: all .3s;
        }
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            >img {
                width: auto;
                height: auto;
                object-fit: cover;
                object-position: center;
                position: absolute;
                top: 50%;
                left: 50%;
                animation: spin 20s infinite linear;
                @include desktop {
                    @keyframes spin {
                        0% {
                            transform: translate(-50%,-50%) rotate(0deg);
                        }
                        100% {
                            transform: translate(-50%,-50%) rotate(360deg);
                        }
                    }
                }
                @include mobile {
                    @keyframes spin {
                        0% {
                            transform: translate(-50%,-50%) scale(3) rotate(0deg);
                        }
                        100% {
                            transform: translate(-50%,-50%) scale(3) rotate(360deg);
                        }
                    }
                }
            }
            >div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .3s;
                &:hover {
                    transform: translate(-50%, -50%) scale(1.1);
                }
                >img {
                    width: 180px;
                    height: 180px;
                }
            }
        }
        &__content {
            text-align: center;
            h4,h5,h6 {
                color: #F5F5F5;;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-family: "AmpleSoft Pro";
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                letter-spacing: 1.8px;
                text-transform: uppercase;
                margin: 0 auto 24px;
            }
            h1,h2,h3 {
                color: #F5F5F5;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-size: px(36);
                font-style: normal;
                font-weight: 700;
                line-height: 40px; 
                margin: 0 auto 24px;
                max-width: 800px;
            }
            p {
                color: #F5F5F5;
                text-align: center;
                leading-trim: both;
                font-family: "AmpleSoft Pro";
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                margin: 0 auto;
                max-width: 800px;
                b,strong {
                    font-weight: 700;
                }
            }
            >*:last-child {
                margin: 0;
            }
        }
        &__button {
            margin: 48px 0 0;
        }
    }
    &__content {
        display: flex;
        gap: 48px;
        align-items: flex-start;
        padding: 48px 0 104px;
        @include mobile {
            flex-direction: column;
        }
        &__column {
            flex: 1;
            &__text {
                h4,h5,h6 {
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(18);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; 
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    margin: 0 0 16px;
                    background: linear-gradient(90deg, #A1A2B1 0%, #44454B 112.33%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    @include mobile {
                        text-align: center;
                    }
                }
                h1,h2,h3 {
                    color: #FFF;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(56);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 64px; 
                    letter-spacing: -1.12px;
                    margin: 0 0 36px;
                    @include mobile {
                        text-align: center;
                    }
                }
                p {
                    color: #C1C2CB;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(24);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px; 
                    @include mobile {
                        text-align: center;
                    }
                    strong, b {
                        font-weight: 700;
                    }
                }
                >*:last-child {
                    margin: 0;
                }
            }
            &__button {
                margin: 48px 0 0;
                >* {
                    margin: 0;
                    @include mobile {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
