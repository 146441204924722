
&__sustentabilidade {
    background: #FAFAFA;
    padding: 72px 0;
    &__box {
        border-radius: 16px;
        overflow: hidden;
        min-height: 411px; 
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            &::after {
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.80) 45.5%, rgba(0, 0, 0, 0.00) 100%);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
            }
        }
        &__content {
            text-align: center;
            h4,h5,h6 {
                color: #F5F5F5;;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-family: "AmpleSoft Pro";
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                letter-spacing: 1.8px;
                text-transform: uppercase;
                margin: 0 auto 24px;
            }
            h1,h2,h3 {
                color: #F5F5F5;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-size: px(36);
                font-style: normal;
                font-weight: 700;
                line-height: 40px; 
                margin: 0 auto 24px;
                max-width: 800px;
            }
            p {
                color: #F5F5F5;
                text-align: center;
                leading-trim: both;
                font-family: "AmpleSoft Pro";
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                margin: 0 auto;
                max-width: 800px;
                b,strong {
                    font-weight: 700;
                }
            }
            >*:last-child {
                margin: 0;
            }
        }
        &__button {
            margin: 48px 0 0;
        }
    }
}
