
&__carreiras {
    padding: 80px 0;
    >.container {
        display: flex;
        align-items: stretch;
        gap: 48px;
        @include mobile {
            flex-direction: column;
        }
        >video,>figure {
            width: 668px;
            height: 490px;
            overflow: hidden;
            border-radius: 16px;
            max-width: 100%;
            @include mobile {
                height: 290px;
            }
        }
        >video {
            object-fit: cover;
            object-position: center;
        }
    }
    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        &__textTop {
            h4,h5,h6 {
                leading-trim: both;
                text-edge: cap;
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                letter-spacing: 1.8px;
                text-transform: uppercase; 
                margin: 0 0 4px; 
                background: linear-gradient(90deg, #A1A2B1 0%, #44454B 112.33%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include mobile {
                    text-align: center;
                }
            }
            h1,h2,h3 {
                color: $textColor;
                leading-trim: both;
                text-edge: cap;
                font-size: px(36);
                font-style: normal;
                font-weight: 700;
                line-height: 40px; 
                margin: 0 0 16px;
                max-width: 361px;
                @include mobile {
                    text-align: center;
                }
                mark,b,i,strong {
                    background: linear-gradient(90deg, $secondaryColor 0%, $tertiaryColor 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(36);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                }
            }
            p {
                color: #30313B;
                leading-trim: both;
                text-edge: cap;
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                @include mobile {
                    text-align: center;
                }
                b, strong {
                    font-weight: 700;
                }
            }
            >*:last-child {
                margin: 0;
            }
        }
        &__button {
            margin: 16px 0 0;
            >* {
                margin: 0;
                @include mobile {
                    margin: 0 auto;
                }
            }
        }
        &__textBottom {
            margin: auto 0 0;
            @include mobile {
                margin: 40px 0 0;
                text-align: center;
            }
            >span {
                color: #18181B;
                leading-trim: both;
                text-edge: cap;
                font-family: "AmpleSoft Pro";
                font-size: px(18);
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
                letter-spacing: 1.8px;
                text-transform: uppercase;
                margin: 0 0 16px;
                display: block;
            }
        }
        &__items {
            display: flex;
            gap: 8.5px;
            @include mobile {
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }
            &__item {
                border-radius: 80px;
                border: 1px solid #E4E4E7;
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 12px;
                background: #fff;
                width: max-content;
                >figure {
                    width: 42px;
                    height: 42px;
                }
                >span {
                    color: #30313B;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(18);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; 
                }
            }
        }
    }
}
