

&__quem_somos {

    background: #F5F5F5;
    padding: 72px 0 48px;
    &__introduction {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 24px;
        margin: 0 0 32.9px;
        @include mobile {
            flex-direction: column;
        }
        >div {
            &:first-child {
                width: 641px;
                max-width: 100%;
                h5, h6 {
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(18);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; 
                    letter-spacing: 1.8px;
                    text-transform: uppercase; 
                    margin: 0 0 16px; 
                    background: linear-gradient(90deg, #A1A2B1 0%, #44454B 112.33%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                h1,h2,h3,h4 {
                    color: $textColor;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(36);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px; 
                    margin: 0 0 24px;
                    mark,b,i,strong {
                        background: linear-gradient(90deg, $secondaryColor 0%, $tertiaryColor 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        leading-trim: both;
                        text-edge: cap;
                        font-size: px(36);
                        font-style: normal;
                        font-weight: 700;
                        line-height: 40px;
                    }
                }
                p {
                    color: #30313B;
                    leading-trim: both;
                    text-edge: cap;
                    font-size: px(18);
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; 
                    b, strong {
                        font-weight: 700;
                    }
                }
                >*:last-child {
                    margin: 0;
                }
       
            }
            &:last-child {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                >figure {
                    width: 194.72px;
                    height: 143.90px;
                }
                >div {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }
        }
    } 
    &__items {
        padding: 0 0 24px;

        .slick-track {
            display: flex !important; 
        }
        
        .slick-slide {
            height: inherit !important;
            display: flex;
            transition: all .3s;
            // opacity: 0;
            >div {
                height: inherit !important;
                display: flex;
                >article {
                    display: flex !important;
                   
                    >div {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        >figure {
                            border-radius: 0 0 16px 16px;
                        }

                    }
                }
            }
            
            // &.slick-cloned:has(+.slick-active) {
            //     opacity: 1;
            // }

            // &.slick-active {
            //     opacity: 1;
            // }

            // &.slick-active+.slick-slide {
            //     opacity: 1;
            // }

        }
        .slick-list {
            overflow: visible;
        }
        article {
            padding: 0 16px;
            @include desktop {
                width: 631px !important;
            }
            >div {
                border-radius: 16px;
                border: 1px solid #F5F5F5;
                background: #FAFAFA;
                box-shadow: 0px 0px 0px 3px #FFF, 0px 0px 2px 0px rgba(0, 0, 0, 0.14) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.04);
                padding: 32px 16px 0px;
                transition: all .3s;
                >div {
                    h1,h2,h3,h4,h5,h6 {
                        color: $textColor;
                        leading-trim: both;
                        text-edge: cap;
                        font-size: px(24);
                        font-style: normal;
                        font-weight: 500;
                        line-height: 36px;
                        margin: 0 0 4px;
                    }
                    p {
                        color: #4B4D5C;
                        leading-trim: both;
                        text-edge: cap;
                        font-size: px(18);
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; 
                    }
                }
                >figure { 
                    width: 100%;
                    height: 280px;
                    position: relative;
                    width: calc(100% + 32px);
                    left: 50%;
                    transform: translateX(-50%);
                    >img {
                        transition: all .3s;
                    }
                }
                &:hover {
                    background: #EFF4FA;
                    
                }
            }
        }
    }
}
 