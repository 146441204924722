
@import "../utils/init";
// @import "../../../node_modules/slick-carousel/slick/slick";
// @import "../../../node_modules/slick-carousel/slick/slick-theme";
@import "slick-carousel/slick/slick";
@import "slick-carousel/slick/slick-theme";

html:has(>.page-template-home) {
    width: 100vw;
    overflow-x: hidden;
}

.home {
    @import "../partials/home/banner";
    @import "../partials/home/quem_somos";
    @import "../partials/home/nossos_numeros";
    @import "../partials/home/sustentabilidade";
    @import "../partials/home/central_de_resultados";
    @import "../partials/home/carreiras";
}
