// Folders
$theme: '.';
$assets: "#{$theme}/assets";
$img: "#{$assets}/img"; 
$fonts: "#{$assets}/fonts";

// COLORS
$neutral100: #EFF3F8;
$neutral200: #C3D2E3;
$neutral300: #8D9DB9;
$neutral400: #535F7B;
$neutral500: #333B4F;
$neutral600: #242936;
$neutral700: #20242C;
$neutral800: #191C24;
$neutral900: #0E0F15;

$primaryColor: #16195A;
$secondaryColor: #00A3DF; 
$tertiaryColor: #4C328E;
$quaternaryColor: #1337E4; 

$titleColor: $primaryColor;
$textColor: #1B1B21;

// FONTS
$fontBase: 'AmpleSoft Pro', sans-serif;
$textFont: $fontBase;
$fontSize: 16px;
$lineHeight: 130%;

// FONTS - WEIGHT
$fontLight: 300;
$fontRegular: 400;
$fontMedium: 500;
$fontSemiBold: $fontMedium;
$fontBold: 700;

$high: #A5FF97;
$low: #FD7979;

// SPACING
$gap: 15px;

//screen sizes
$screen-xl: 1200px;
$screen-lg: 992px;
$screen-md: 768px;
$screen-sm: 576px;

//button sizes
$btnlg: 200px;
$btnmd: 165px;
$btnsm: 105px;

