

@include desktop {
    body.admin-bar {
        .home__banner {
            height: calc(100dvh - 32px);
        }    
    }
}

&__banner {
    position: relative;
    width: 100%;
    height: 579px;
    z-index: 1;
    background: #000;

    &__slick {
        position: relative;
        z-index: 1;

        >* {
            transition: all .3s;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 45px;
            height: 45px;
            border: 5px solid darken($primaryColor, 25%); 
            border-radius: 50%;
            animation: spin 1s linear infinite, colorChange 5s ease-in-out infinite alternate;
            opacity: 0;
            @keyframes spin {
                to {
                    transform: translate(-50%, -50%) rotate(360deg);
                }
            }
            @keyframes colorChange {
                0% {
                    border-top-color: $secondaryColor;
                }
                50% {
                    border-top-color: $tertiaryColor;
                }
                100% {
                    border-top-color: $quaternaryColor;
                }
                100% {
                    border-top-color: $quaternaryColor;
                }
            }
        }

        &:not(.slick-initialized) {
            position: relative;
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
            >* {
                opacity: 0;
            }
        }

        &,
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide>div,
        .slick-slide>div>div {
            height: 100%;
        }
        .slick-slide {
            display: unset !important;
        }
    }

    &__item {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(269deg, rgba(0, 0, 0, 0.00) -4.04%, rgba(0, 0, 0, 0.80) 56.54%);
            z-index: 2;
        }

        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
   
        &--video {
            video {
                position: absolute;
                left: 0;
                bottom: 0;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100% !important;
            position: relative;
            z-index: 3;
            >*:last-child {
                margin: 0;
            }
        }

        h5,h6 {
            leading-trim: both;
            text-edge: cap;
            font-size: px(18);
            font-style: normal;
            font-weight: 400;
            line-height: 24px; 
            letter-spacing: 1.8px;
            text-transform: uppercase;
            background: linear-gradient(90deg, #A1A2B1 0%, #44454B 112.33%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0 0 24px;
            @include mobile {
                margin: 0 0 12px;
            }
        }

        h1,h2,h3,h4 {
            color: #FFF;
            leading-trim: both;
            text-edge: cap;
            font-size: px(56);
            font-style: normal;
            font-weight: 700;
            line-height: 64px; 
            letter-spacing: -1.12px;
            margin: 0 0 24px;
            max-width: 592px;
            @include mobile {
                font-size: 36px;
                line-height: 40px; 
                margin: 0 0 12px;
            }
            mark,b,i,strong {
                background: linear-gradient(90deg, $secondaryColor 0%, $tertiaryColor 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                leading-trim: both;
                text-edge: cap;
                font-style: normal;
                font-weight: 700;
            }
        }

    

        p {
            color: #C1C2CB;
            leading-trim: both;
            text-edge: cap;
            font-size: px(18);
            font-style: normal;
            font-weight: 400;
            line-height: 24px; 
            max-width: 481px;
            b, strong {
                color: #C1C2CB;
                leading-trim: both;
                text-edge: cap;
                font-size: px(18);
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
            }
        }
    }
}