// Mixins para encurtar o uso de @media

@mixin mobile {
    @media(max-width: 1199px) {
        @content;
    }
}

@mixin desktop {
    @media(min-width: 1200px){
        @content;
    }
}

// Borda gradiente

@mixin gradientBorderOnHover() {
    position: relative;
    z-index: 0;
    background: #fff;

    &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 8px;
        background: linear-gradient(to right, $primaryColor, $secondaryColor);
        z-index: -2;
        transition: all 0.3s;
        opacity: 0; 
    }

    &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: #fff;
        z-index: -1;
        transition: all 0.3s;
        opacity: 0;
    }

    &:hover {
        &::before {
            animation: fadeIn .3s linear .2s forwards; 
            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
        &::after {
            opacity: 1;
        }
    }
}
